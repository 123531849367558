






































































import { Component, Vue } from 'vue-property-decorator';
import { IUserProfileUpdate } from '@/interfaces';
import { dispatchGetRoles, dispatchGetUsers, dispatchUpdateUser } from '@/store/admin/actions';
import { readAdminOneUser, readAdminRoles } from '@/store/admin/getters';
import ButtonModalConfirmation from '@/components/ButtonModalConfirmation.vue';

@Component({
  components: {
    ButtonModalConfirmation,
  },
})
export default class EditUser extends Vue {
  public displayName: string = '';
  public email: string = '';
  public roleId: number = 3;
  public setPassword = false;
  public password1: string = '';
  public password2: string = '';

  $refs!: {
    observer
  }

  public async mounted() {
    await dispatchGetRoles(this.$store);
    await dispatchGetUsers(this.$store);
    this.reset();
  }

  get roles() {
    return readAdminRoles(this.$store);
  }

  get user() {
    return readAdminOneUser(this.$store)(parseInt(this.$router.currentRoute.params.id));
  }

  public reset() {
    this.setPassword = false;
    this.password1 = '';
    this.password2 = '';
    this.$refs.observer.reset();
    if (this.user) {
      this.displayName = this.user.display_name;
      this.email = this.user.email;
      this.roleId = this.user.role.id;
    }
  }

  public cancel() {
    this.$router.back();
  }

  public submit() {
    this.$refs.observer.validate().then(async () => {
      const updatedProfile: IUserProfileUpdate = {};
      if (this.displayName && this.displayName !== this.user?.display_name) {
        updatedProfile.display_name = this.displayName;
      }
      if (this.email && this.email !== this.user?.email) {
        updatedProfile.email = this.email;
      }
      updatedProfile.role_id = this.roleId;
      if (this.setPassword && this.password1) {
        updatedProfile.password = this.password1;
      }
      try {
        await dispatchUpdateUser(this.$store, { id: this.user!.id, user: updatedProfile });
        this.$router.push('/main/admin/users');
      } catch (e) {
        console.error(e.message);
      }
    });
  }

}
