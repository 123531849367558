var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{staticClass:"secondary--text text--lighten-2",attrs:{"flat":"","dense":"","light":""}},[_c('v-toolbar-title',[_c('router-link',{attrs:{"to":"/main/admin/users"}},[_vm._v(" Users ")]),_c('span',{staticClass:"text-subtitle-1"},[_c('span',{staticClass:"mr-1"},[_vm._v("/")]),_vm._v("Edit "),_c('span',{staticClass:"mr-1"},[_vm._v("/")]),_vm._v(_vm._s(_vm.user.user_id)+" ")])],1)],1),_c('v-container',[_c('v-card',{attrs:{"width":"75%"}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var pristine = ref.pristine;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"User ID","disabled":""},model:{value:(_vm.user.user_id),callback:function ($$v) {_vm.$set(_vm.user, "user_id", $$v)},expression:"user.user_id"}}),_c('ValidationProvider',{attrs:{"name":"Email","mode":"eager","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"E-mail (unique)","type":"email","error-messages":errors},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('v-checkbox',{attrs:{"dense":"","label":"Change password"},model:{value:(_vm.setPassword),callback:function ($$v) {_vm.setPassword=$$v},expression:"setPassword"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Role","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Role","items":_vm.roles,"item-text":"name","item-value":"id","error-messages":errors},model:{value:(_vm.roleId),callback:function ($$v) {_vm.roleId=$$v},expression:"roleId"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Display name","mode":"eager","rules":"min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Display Name","error-messages":errors},model:{value:(_vm.displayName),callback:function ($$v) {_vm.displayName=$$v},expression:"displayName"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Password","mode":"eager","rules":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"password",attrs:{"dense":"","type":"password","autocomplete":"new-password","label":"Password","error-messages":errors,"disabled":!_vm.setPassword},model:{value:(_vm.password1),callback:function ($$v) {_vm.password1=$$v},expression:"password1"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Password confirmation","mode":"eager","rules":"confirms:@Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","type":"password","autocomplete":"new-password","label":"Confirm password","error-messages":errors,"disabled":!_vm.setPassword},model:{value:(_vm.password2),callback:function ($$v) {_vm.password2=$$v},expression:"password2"}})]}}],null,true)})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"secondary",attrs:{"tile":"","small":""},on:{"click":_vm.cancel}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"secondary",attrs:{"tile":"","small":""},on:{"click":_vm.reset}},[_vm._v("Reset")]),_c('ButtonModalConfirmation',{attrs:{"title":"Please confirm action","text":'Editing user ' + _vm.user.user_id,"disabledButton":invalid || pristine},on:{"ok":_vm.submit}})],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }